<template>
  <div>
    <div>
      <div class="newsLook">
        <el-form label-width="100px" :model="newsData" :rules="newsDataRules" ref="fieldRef">
          <el-form-item label="领域名称：" prop="fieldName">
            <el-input v-model="newsData.fieldName"></el-input>
          </el-form-item>
          <el-form-item label="别称：">
            <el-input v-model="newsData.name"></el-input>
          </el-form-item>
          <el-form-item label="描述：">
            <el-input
              v-model="newsData.introduction"
              :autosize="{ minRows: 4, maxRows: 20 }"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="领域检索：">
            <el-select
              v-model="newsData.father"
              filterable
            remote
            reserve-keyword
              placeholder="请输入"
              @change="selectOne"
              :remote-method="searchField"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.fieldName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级领域：">
            <p
              v-if="newsData.fatherId == '0' && !newsData.knowField.length > 0"
              style="color: #7c7f8e;"
            >该标签为一级标签</p>
            <el-tag
              v-for="tag in newsData.knowField"
              :key="tag.id"
              closable
              @close="handleClose(tag)"
              style="margin-right:10px"
              v-else
            >{{ tag.fieldName }}</el-tag>
          </el-form-item>
          <div></div>
          <el-form-item label="下级领域：" v-if="personType">
            <el-input
              v-model="list"
              type="textarea"
              placeholder="此标签没有下级标签"
              disabled
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="筛选标签：" prop="screen">
            <el-radio-group v-model="newsData.screen">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="company_right" v-if="personType">
          <div>创建人：{{newsData.createName}}</div>
          <div>修改人：{{newsData.updateName}}</div>
          <div>创建时间：{{newsData.companyIncludedTime}}</div>
          <div>修改时间：{{newsData.companyUpdateTime}}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="btnClick">确定</el-button>
      <el-button @click="cancelItem">返回</el-button>
    </div>
  </div>
</template>

<script>
import DicRadioGroup from '@/components/DicRadioGroup.vue'
import { editFields, getComBox,getField } from '@/api/field'
import { getKnowFieldList } from '@/api/common'
export default {
  name: 'newsInfo',
  components: {
    DicRadioGroup,
  },
  computed: {
    newsDatas() {
      return Number(this.$route.query.newsData)
    },
    personType() {
      return Number(this.$route.query.personType)
    },
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  created() {
    // this.searchField();
    if (this.personType) {
                    this.search()
    }
  },
  data() {
    return {
      newsData: {
        fieldName: '',
        name: '',
        introduction: '',
        knowField: [],
        screen: null,
        father: '',
      },
      newsDataRules: {
        fieldName: [
          { required: true, message: '请输入领域名称', trigger: 'blur' },
        ],
        // introduction: [
        //   { required: true, message: "请输入简介", trigger: "blur" },
        // ],
        screen: [
          { required: true, message: '请填写筛选标签', trigger: 'blur' },
        ],
      },
      data: '',
      queryInfo: {
        positionType: '',
      },
      options: [],
      list: '',
    }
  },
  methods: {
    cancelItem() {
      this.$router.go(-1)
    },
    async btnClick() {
      //修改
      Object.assign(this.newsData, {
        Authorization: this.Authorization,
      })
      this.$refs.fieldRef.validate(async (valid) => {
        if (valid) {
          // this.newsData.knowField = this.newsData.knowField.map(item => {
          //   return item.value
          // })
          // this.newsData.knowTecheslist = this.newsData.knowTecheslist.split("|")
          if (!this.newsData.knowField.length > 0) {
            this.newsData.knowField = [{ id: 0 }]
          }
          const res = await editFields(this.newsData)
          if (res.code == 200) {
            if (this.personType) {
              this.$message.success('编辑成功')
              this.$router.go(-1)
            } else {
              this.$message.success('添加成功')
              this.$router.go(-1)
            }
          }else{
            this.$message.error(res.msg)
          }
          const res1 = await getKnowFieldList()
          res1.data = JSON.parse(
            JSON.stringify(res1.data).replace(/fieldName/g, 'codeName')
          )
          sessionStorage.setItem('field', JSON.stringify(res1.data))
        }
      })
    },
    async searchField(query) {
      if (query !== "") {
        setTimeout(async () => {
          const res = await getComBox({fieldName:query});
                const dataList = res.data;
      this.options = dataList.map((item) => {
        return (item = {
          id: item.id,
          fieldName: item.fieldName,
        });
      });
        }, 200);
      } else {
        this.options = [];
      }
    },
    selectOne() {
      const valueOne = this.options.filter(
        (item) => item.id === this.newsData.father
      )
      this.newsData.knowField.push(valueOne[0])
    },
    remoteMethod(query) {},
    handleClose(tag) {
      const index = this.newsData.knowField.indexOf(tag)
      this.newsData.knowField.splice(index, 1)
    },
    async search(){
      const res = await getField({id:this.newsDatas})
      if(res.code == 200){
        this.newsData = res.data
      this.list = this.newsData.knowTecheslist.map((item) => {
        return (item = item.fieldName)
      })
      this.list = this.list.join(',')
      if (!this.newsData.knowField) {
        this.newsData.knowField = []
      }
      }
    }
  },
  mounted() {
    document.getElementsByClassName('newsLook')[0].style.height =
      document.body.clientHeight - 220 + 'px'
  },
}
</script>

<style lang="less" scoped>
.newsLook {
  background-color: white;
  padding: 30px 10px 10px 10px;
  overflow: auto;
  // height: 720px;
  .el-form {
    .el-form-item {
      margin-bottom: 20px;
      .el-textarea {
        width: 80%;
      }
      .el-input {
        width: 470px;
      }
      /deep/ .el-textarea__inner {
        height: 100%;
      }
    }
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: white;
  color: #606266;
  cursor: default;
}
.company_right {
  margin: 0px 20px;
  font-size: 13px;
  color: #7c7f8e;
}
</style>
