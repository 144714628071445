import request from "@/utils/request";

// 浏览领域类别
export function getFields(data) {
  return request({
    url: "/system/knowField/getKnowFieldSearchList",
    method: "post",
    data
  });
}

// 新增
export function field(data) {
  return request({
    url: "/system/knowField/importField",
    method: "post",
    data
  });
}

// 修改新增
export function editFields(data) {
  return request({
    url: "/system/knowField/insertUpdateKnowField",
    method: "post",
    data
  });
}

// 单个查询
export function getField(data) {
  return request({
    url: "/system/knowField/getKnowFielaByid",
    method: "get",
    params: data
  });
}

// 查询一二级标签
export function getComBox(data) {
  return request({
    url: "/system/knowField/getComboBox",
    method: "get",
    params: data
  });
}

// 删除
export function deleteField(data) {
  return request({
    url: "/system/knowField/deleteKnowFieldByid",
    method: "post",
    data
  });
}
